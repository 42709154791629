import Vue from "vue";
import VueRouter from "vue-router";
const Index = () => import("views/index/index");
const Login = () => import("views/login/login");
const LowCodePlatform = () => import("views/lowCodePlate/lowCodePlate");
const IOTPlatform = () => import("views/IOTPlatform/IOTPlatform");
const BIPlatform = () => import("views/BIPlatform/BIPlatform");
const AboutUs = () => import("views/AboutUs/AboutUs");
const CustomerQljgj = () => import("views/CustomerQljgj/CustomerQljgj");
const CustomerAOYA = () => import("views/CustomerAOYA/CustomerAOYA");
const SolutionOne = () => import("views/SolutionOne/SolutionOne");
const SolutionTwo = () => import("views/SolutionTwo/SolutionTwo");
const SolutionThree = () => import("views/SolutionThree/SolutionThree");
const CustomerDZMotor = () => import("views/CustomerDZMotor/CustomerDZMotor");
const CustomerJXSponge = () => import("views/CustomerJXSponge/CustomerJXSponge");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/Index"
  },
  {
    name: "Index",
    path: "/Index",
    component: Index
  },
  {
    name: "Login",
    path: "/Login",
    component: Login
  },
  {
    name: "LowCodePlatform",
    path: "/LowCodePlatform",
    component: LowCodePlatform
  },
  {
    name: "IOTPlatform",
    path: "/IOTPlatform",
    component: IOTPlatform
  },
  {
    name: "BIPlatform",
    path: "/BIPlatform",
    component: BIPlatform
  },
  {
    name: "AboutUs",
    path: "/AboutUs",
    component: AboutUs
  },
  {
    name: "CustomerQljgj",
    path: "/CustomerQljgj",
    component: CustomerQljgj
  },
  {
    name: "CustomerAOYA",
    path: "/CustomerAOYA",
    component: CustomerAOYA
  },
  {
    name: "SolutionOne",
    path: "/SolutionOne",
    component: SolutionOne
  },
  {
    name: "SolutionTwo",
    path: "/SolutionTwo",
    component: SolutionTwo
  },
  {
    name: "SolutionThree",
    path: "/SolutionThree",
    component: SolutionThree
  },
  {
    name: "CustomerDZMotor",
    path: "/CustomerDZMotor",
    component: CustomerDZMotor
  },
  {
    name: "CustomerJXSponge",
    path: "/CustomerJXSponge",
    component: CustomerJXSponge
  }
];

const router = new VueRouter({
  routes
});

export default router;
